import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useAlert } from "react-alert"
import { useAuth } from "../../context/auth-context"
import { useUser } from "../../context/user-context"
import { isBrowser } from "../../utils/browser"
import Button from "../button"

function VerifyForm() {
  const user = useUser()
  const alert = useAlert()
  const { MFAVerification, MFAResend, refetchUser, logout } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isResendLoading, setIsResendLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    code: "",
  })
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!user) {
      navigate("/login")
    }
  }, [JSON.stringify(user)])

  const handleChange = ({ target }) => {
    setCredentials({
      ...credentials,
      [target.name]: target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setIsLoading(true)

    MFAVerification(credentials.code)
      .then((res) => {
        if (res?.data?.verifyMFA?.status === 200) {
          refetchUser().then(() => {
            navigate("/app/dashboard")
          })
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleResend = () => {
    setIsResendLoading(true)

    MFAResend()
      .then((res) => {
        if (res?.data?.resendMFA?.status === 201) {
          if (isBrowser()) {
            alert.info(`We've resent the email to ${user?.email}`)
          }
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setIsResendLoading(false)
      })
  }

  const logoutUser = () => {
    logout().then(() => navigate("/"))
  }

  return (
    <form onSubmit={handleSubmit} className="lg:px-8 pt-6 lg:pb-8">
      <div className="w-full">
        <label
          className="block md:w-1/2 pr-3 mt-4 mb-2"
          htmlFor="subject"
        ></label>
        <input
          onChange={handleChange}
          className="default-form-field w-full"
          id="code"
          name="code"
          type="text"
          placeholder="Code"
          aria-label="Code"
        />
        {error && <small className="text-error">Invalid code.</small>}
      </div>
      <div className="py-4 mx-auto">
        <Button
          buttonText={isLoading ? "Verifying code..." : "Submit"}
          buttonType="btn in-app -primary"
          additionalClasses={`${isLoading ? "w-auto" : ""}`}
          disabled={isLoading}
          eventName={{
            eventDomain: "App",
            eventLocation: "MFAForm",
            eventAction: "Click",
            eventActionName: "Submit Code",
          }}
        />
        <div
          onClick={handleResend}
          className="body-text py-3 block cursor-pointer"
        >
          {isResendLoading ? "Resending code..." : "Resend Code"}
        </div>
        <div
          onClick={logoutUser}
          className="body-text py-3 block cursor-pointer"
        >
          Logout
        </div>
      </div>
    </form>
  )
}

VerifyForm.propTypes = {}

export default VerifyForm
