import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FullPageLayout from "../components/full-page-layout"
import MfaForm from "../components/auth/mfa-form"

function MfaPage() {
  const { allPrismicLoginPage } = useStaticQuery(graphql`
    query MfaPageQuery {
      allPrismicLoginPage {
        edges {
          node {
            data {
              right_image {
                alt
                url
              }
              mfa_title {
                html
                text
              }
            }
          }
        }
      }
    }
  `)

  const { right_image, mfa_title } = allPrismicLoginPage.edges[0].node.data

  return (
    <FullPageLayout rightImageUrl={right_image.url}>
      <div className="flex items-center pt-32 lg:pt-36">
        <div className="text-center w-full px-6 lg:px-16">
          <div className="w-full flex items-center">
            <div className="w-full">
              <h1 className="headline-text">{mfa_title?.text}</h1>
            </div>
          </div>
          <div className="mx-auto md:w-2/3">
            <MfaForm />
          </div>
        </div>
      </div>
    </FullPageLayout>
  )
}

export default MfaPage
